import styled from 'styled-components';


export const TransportLautH1 = styled.h1`
    color: #fff;
    font-size: 78px;
    text-align: center;
    margin-bottom: 2rem;
    padding-top: -3rem;
    font-family: 'Kanit', sans-serif;

    @media screen and (max-width: 3840px) {
        font-size: 140px;
    } 

    @media screen and (max-width: 2048px) {
        font-size: 78px;
    } 
    

    @media screen and (max-width: 760px) {
        font-size: 58px;
    } 
`;

