import styled from 'styled-components';
import { Link } from 'react-router-dom';

const Container = styled.div`
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;

  // border: 2px solid red;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-box-sizing: border-box;

  display: flex;
  flex-direction: column;
  max-width: 100vw;
  overflow: hidden;
`;

const Background = styled.img`
  position: relative;
  top: 0;
  left: 0;
  margin-top: 0;
  padding-top: 0;

  -o-object-fit: cover;
  object-fit: cover;

  height: 100vh;
  max-width: 100%;
  overflow: hidden;
`;

const Content = styled.div`
  position: absolute;
  top: 70px;
  left: 0;
  right: 0;
  bottom: 0;

  display: flex;
  justify-content: center;

  background: linear-gradient(
      180deg,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0.6) 0%
    ),
    linear-gradient(180deg, rgba(0, 0, 0, 0.2) 0%, transparent 100%);
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  align-self: center;
`;

const Heading = styled.h1`
  display: inline-block;
  box-sizing: border-box;
  color: #fff;

  text-align: center;
  font-family: 'Kanit', sans-serif;
  font-size: 1.65rem;

  margin-bottom: 1rem;

  @media screen and (min-width: 960px) {
    font-size: 3rem;
  }
`;

const Button = styled(Link)`
  text-decoration: none;
  text-align: center;
  font-family: 'Kanit:wght@600', sans-serif;
  font-weight: 700;
  font-size: 1.25rem;
  color: ${({ primary }) => (primary ? '#052e69' : '#fff')};
  background: ${({ primary }) => (primary ? '#fff' : '#052e69')};

  box-sizing: border-box;
  border: 1px solid ${({ primary }) => (primary ? '#052e69' : '#fff')};
  border-radius: 0.3rem;

  margin: 0.25rem;
  padding: 0.75rem;

  min-width: 240px;
`;

const ButtonGroup = styled.div`
  display: flex;

  @media screen and (max-width: 420px) {
    flex-direction: column;
  }
`;

const Home = {
  Container,
  Background,
  Content,
  Center,
  Heading,
  Button,
  ButtonGroup,
};

export default Home;
