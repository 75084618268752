import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
import { FaTimes } from 'react-icons/fa';

export const SidebarContainer = styled.aside`
  position: fixed;
  z-index: 999;
  width: 100%;
  height: 100%;
  // background: #052e69;
  background: linear-gradient(
      to left,
      rgba(5, 46, 105, 0.9) 100%,
      rgba(5, 46, 105, 0) 100%
    ),
    linear-gradient(to left, rgba(5, 46, 105, 0.9) 60%, transparent 100%);
  display: grid;
  align-items: right;
  top: 0;
  right: 0;
  transition: 0.3s ease-in-out;
  opacity: ${({ isOpen }) => (isOpen ? '100%' : '0')};
  top: ${({ isOpen }) => (isOpen ? '0' : '-100%')};
  font-family: 'Kanit:wght@600', sans-serif;
`;

export const CloseIcon = styled(FaTimes)`
  color: #fff;
`;

export const Icon = styled.div`
  position: absolute;
  top: 1.2rem;
  right: 1.5rem;
  background: transparent;
  font-size: 2rem;
  cursor: pointer;
  outline: none;
`;

export const SidebarWrapper = styled.div`
  color: #fff;
  align-items: center;
  margin-top: 100px;
`;

export const SidebarMenu = styled.ul`
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: repeat(12, 40px);
  text-align: right;
  flex-direction: column;
  align-items: center;
  margin-right: 30px;
  // width: 90%;

  // @media screen and (max-width: 320px) {
  //     width: 170px;
  // }

  // @media screen and (max-width: 680px) {
  //     grid-template-rows: repeat(6, 60px);
  //     margin-right: 240px;
  // }

  // @media screen and (max-width: 640px) {
  //     grid-template-rows: repeat(6, 60px);
  //     margin-right: 210px;
  // }

  // @media screen and (max-width: 580px) {
  //     grid-template-rows: repeat(6, 60px);
  //     margin-right: 190px;
  // }

  // @media screen and (max-width: 570px) {
  //     grid-template-rows: repeat(6, 60px);
  //     margin-right: 165px;
  // }

  // @media screen and (max-width: 360px) {
  //     grid-template-rows: repeat(6, 60px);
  //     margin-right: 95px;
  // }
`;

export const SidebarLink = styled(LinkR)`
  display: grid;
  align-items: right;
  text-align: right;
  justify-content: right;
  font-size: 1.2rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin-top: 0px;

  &:hover {
    color: #30c1ff;
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarNoLink = styled.span`
  display: grid;
  align-items: right;
  text-align: right;
  justify-content: right;
  font-size: 1.2rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  margin-top: 0px;

  &:hover {
    color: #30c1ff;
    transition: 0.2s ease-in-out;
  }
`;

export const SidebarLinkParent = styled.h1`
  display: grid;
  align-items: right;
  justify-content: right;
  font-size: 1.2rem;
  text-decoration: none;
  list-style: none;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  margin-top: 10px;
`;

export const SidebarLinkChild = styled(LinkR)`
  display: grid;
  align-items: right;
  grid-template-columns: 1fr;
  text-align: right;
  justify-content: right;
  font-size: 0.9rem;
  transition: 0.2s ease-in-out;
  text-decoration: none;
  color: #fff;
  cursor: pointer;
  padding: 0 0 0px 0;
  margin-top: 0px;

  &:hover {
    color: #30c1ff;
    transition: 0.2s ease-in-out;
  }
`;

// export const SidebarChild = styled.div`
//     display: grid;
//     grid-template-columns: 1fr;
//     grid-template-rows: repeat(6, 86px);
//     text-align: right;
//     flex-direction: column;
//     align-items: right;
//     margin-right: 0px;
//     width: auto;
// `;
