import React, { useContext } from "react";

import { LangContext } from "../../Context/lang.context";
import HomeStyle from "./Home.styled";
import homeBackground from "../../images/herobg.jpg";

import HomeContent from "./home.translation.json";

const links = {
  transporter: {
    pathname: "/TransportDarat",
    hash: "contact-us-transporter",
  },
  customer: {
    pathname: "/Warehouse",
    hash: "contact-us-warehouse",
  },
};

const Home = () => {
  const { language } = useContext(LangContext);
  return (
    <HomeStyle.Container>
      <HomeStyle.Background src={homeBackground} type="images/jpg" />
      <HomeStyle.Content>
        <HomeStyle.Center>
          <HomeStyle.Heading>
            {HomeContent[language].header1}
            <br />
            {HomeContent[language].header2}
            <br />
            {HomeContent[language].header3}
          </HomeStyle.Heading>
          <HomeStyle.ButtonGroup>
            <HomeStyle.Button to={links.customer} primary="true">
              {HomeContent[language].button1}
            </HomeStyle.Button>
            <HomeStyle.Button to={links.transporter}>
              {HomeContent[language].button2}
            </HomeStyle.Button>
          </HomeStyle.ButtonGroup>
        </HomeStyle.Center>
      </HomeStyle.Content>
    </HomeStyle.Container>
  );
};

export default Home;
