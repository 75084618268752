import React from 'react';
import './App.css';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import Home from './pages/Home';
import HomeAbout from './pages/About';
import HomeWarehouse from './pages/Warehouse';
import HomeLaut from './pages/TransportLaut';
import HomeUdara from './pages/TransportUdara';
import HomeCargo from './pages/Cargo';
import HomeDarat from './pages/TransportDarat';
// import Navbar from './components/Navbar/Navbar';

import { LangContext } from './Context/lang.context';
import ScrollToTop from './components/Navbar/ScrollToTop';
import Utils from './utils';

function App() {
  const [language, setLanguage] = React.useState('id');
  const value = { language, setLanguage };

  Utils.getCountryCodeByIp(setLanguage);

  return (
    <LangContext.Provider value={value}>
      <Router>
        <ScrollToTop>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/About" component={HomeAbout} />
            <Route path="/Warehouse" component={HomeWarehouse} />
            <Route path="/TransportDarat" component={HomeDarat} />
            <Route path="/TransportLaut" component={HomeLaut} />
            <Route path="/TransportUdara" component={HomeUdara} />
            <Route path="/Cargo" component={HomeCargo} />
          </Switch>
        </ScrollToTop>
      </Router>
    </LangContext.Provider>
  );
}

export default App;
