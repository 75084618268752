import React from 'react';
import About1 from '../../images/about1.jpg';
import About2 from '../../images/about2.jpg';
import Customer from '../../images/PartnerImages/customer.png';
import Vendor from '../../images/PartnerImages/vendor.png';
// import Dekoruma from '../../images/PartnerImages/dekoruma.jpg';
// import Derochi from '../../images/PartnerImages/derochi.jpg';
// import MrDIY from '../../images/PartnerImages/diy2.jpg';
// import MazzBrim from '../../images/PartnerImages/mazbrim.jpg';
// import Bstrans from '../../images/PartnerImages/bstrans.jpg';
// import Hokindo from '../../images/PartnerImages/hokindo.jpg';
// import Primex from '../../images/PartnerImages/primex2.jpg';
// import Sds from '../../images/PartnerImages/sds.jpg';
import {
  AboutContainer,
  HeroBg,
  AboutBg,
  AboutH1,
  AboutContent,
  Image1,
  Image2,
  Content,
  VisiMisiContainer,
  VisiMisiWrapper,
  VisiMisiRow,
  Column1,
  Column2,
  ImgWrap,
  Img,
  TextWrapper,
  Subtitle,
  AboutCard,
  HeadingM,
  HeadingV,
  Red,
  PartnerContainer,
  PartnerWrapper,
  PartnerHead,
} from './AboutElements';
import './About.css';
import AboutGrafik from '../../images/aboutgrafik.jpg';

import AboutTrans from './About.translation.json';
import { LangContext } from '../../Context/lang.context';

const About = ({
  lightText,
  headLine1,
  headLine2,
  darkText,
  description1,
  description2,
  description3,
  description4,
  description5,
}) => {
  const { language } = React.useContext(LangContext);
  return (
    <>
      <AboutContainer>
        <AboutBg>
          <HeroBg>
            <Image1 className="card-item1" src={About1} type="images/jpg" />
            <Image2 className="card-item2" src={About2} type="images/jpg" />
          </HeroBg>
        </AboutBg>
        <AboutContent>
          <AboutH1 className="lineAbout1">
            {AboutTrans[language].about}{' '}
            <Red className="lineAbout2">{AboutTrans[language].us}</Red>
          </AboutH1>
          <Content className="about-content1">
            {AboutTrans[language].shortDesc}
          </Content>
        </AboutContent>
      </AboutContainer>

      {/* <p>
        Dengan kombinasi dasar pengetahuan lengkap dan pengalaman kerja disertai jaringan yang luas memungkinkan kami memberikan layanan prima dengan harga
             yang kompetitif sesuai dengan kebutuhan klien.
        </p> */}

      <VisiMisiContainer>
        <VisiMisiWrapper>
          <VisiMisiRow>
            <Column1>
              <ImgWrap>
                <Img src={AboutGrafik} type="images/png" />
              </ImgWrap>

              <HeadingV lightText={lightText}>
                {AboutTrans[language].visiHead}
              </HeadingV>
              <AboutCard>
                <Subtitle>{AboutTrans[language].visiOne}</Subtitle>
              </AboutCard>
            </Column1>

            <Column2>
              <TextWrapper>
                <HeadingM lightText={lightText}>
                  {AboutTrans[language].misiHead}
                </HeadingM>
                <Subtitle darkText={darkText}>
                  {AboutTrans[language].misiOne}
                </Subtitle>
                <br />
                <Subtitle darkText={darkText}>
                  {AboutTrans[language].misiTwo}
                </Subtitle>
                <br />
                <Subtitle darkText={darkText}>
                  {AboutTrans[language].misiThree}
                </Subtitle>
                <br />
              </TextWrapper>
            </Column2>
          </VisiMisiRow>
        </VisiMisiWrapper>
      </VisiMisiContainer>

      <PartnerContainer>
        <PartnerWrapper>
          <PartnerHead className="lineAbout1">
            {AboutTrans[language].partner}{' '}
            <Red className="lineAbout2">{AboutTrans[language].our}</Red>
          </PartnerHead>
        </PartnerWrapper>
        <div className="galleryA">
          <div className="imageA">
          <PartnerWrapper>
          <PartnerHead>
            {AboutTrans[language].customer}{' '}
          </PartnerHead>
        </PartnerWrapper>
            <span>
              <Img src={Customer} type="images/jpg" />
            </span>
          </div>
          <div className="imageA">
          <PartnerWrapper>
          <PartnerHead>
            {AboutTrans[language].vendor}{' '}
          </PartnerHead>
        </PartnerWrapper>
            <span>
              <Img src={Vendor} type="images/jpg" />
            </span>
          </div>
        </div>
      </PartnerContainer>
    </>
  );
};

export default About;
