import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
// import Form from './Form';
import Warehouse from './Warehouse';
import { homeObjTwo } from './WarehouseElements';

import utils from '../../utils';

const HomeWarehouse = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (location.hash) {
      let Y = utils.getYCoordinate(location.hash.slice(1), -50);
      window.scrollTo({ top: Y, behavior: 'smooth' });
    }
  });

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <Warehouse {...homeObjTwo} />
      {/* <Form /> */}
      <Footer />
    </>
  );
};

export default HomeWarehouse;
