import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import Footer from '../../components/Footer/Footer';
import Navbar from '../../components/Navbar/Navbar';
import Sidebar from '../../components/Sidebar/Sidebar';
import TransportDarat from './TransportDarat';
// import Form from './Form';
import { homeObjTwo } from './TransportDaratElements';

import utils from '../../utils';

const HomeDarat = () => {
  const [isOpen, setIsOpen] = useState(false);
  const location = useLocation();

  const toggle = () => {
    setIsOpen(!isOpen);
  };

  useEffect(() => {
    if (location.hash) {
      let Y = utils.getYCoordinate(location.hash.slice(1), -120);
      window.scrollTo({ top: Y, behavior: 'smooth' });
    }
  });

  return (
    <>
      <Sidebar isOpen={isOpen} toggle={toggle} />
      <Navbar toggle={toggle} />
      <TransportDarat {...homeObjTwo} />
      {/* <Form /> */}
      <Footer />
    </>
  );
};

export default HomeDarat;
