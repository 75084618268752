import React from "react";
import Image from "../../images/logo.png";
import {
  FooterContainer,
  FooterWrapper,
  FooterLinksContainer,
  FooterLinksWrapper,
  FooterLinkItems,
  FooterLinkTitle,
  FooterLink,
  WebsiteRights,
  FooterLogo,
  FooterLogoText,
  FooterLogoWrap,
  FooterText,
  FooterLinkA,
  WebRightSbl,
  WebRightSeparator,
  WebRightAlas,
} from "./FooterElements";

import FooterTrans from "./Footer.translation.json";
import { LangContext } from "../../Context/lang.context";

const Footer = () => {
  const { language } = React.useContext(LangContext);
  return (
    <FooterContainer className="footer-bg">
      <FooterWrapper className="footer-container">
        <FooterLinksContainer className="footer-content-wrapper">
          <FooterLogoWrap>
            <FooterLogo
              src={Image}
              type="image/jpg"
              alt="alt"
              height="60px"
            ></FooterLogo>
            <FooterLogoText>
              <strong>SAYAP BUANA LOGISTIK</strong>
            </FooterLogoText>
          </FooterLogoWrap>

          <FooterLinksWrapper>
            <FooterLinkItems>
              <FooterLinkTitle>{FooterTrans[language].contact}</FooterLinkTitle>
              <FooterText>021-82636779</FooterText>
              <FooterText>cs@sayap.co.id</FooterText>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>
                {FooterTrans[language].ourServices}
              </FooterLinkTitle>
              <FooterLink to="/TransportDarat">
                {FooterTrans[language].serviceThree}
              </FooterLink>
              <FooterLink to="/Warehouse">
                {FooterTrans[language].serviceOne}
              </FooterLink>
              <FooterLink to="/Cargo">
                {FooterTrans[language].serviceTwo}
              </FooterLink>
            </FooterLinkItems>
            <FooterLinkItems>
              <FooterLinkTitle>
                {FooterTrans[language].officeAddress}
              </FooterLinkTitle>
              <FooterText>
                Office : Jl. Casablanca raya kav.88 office lt.9 unit A gedung 88
                kota Casablanca kel. menteng dalam kec. tebet, jakarta selatan.
              </FooterText>
              <FooterText>
                Operational : Ruko Marketplace Blok PR.2 No.11 Grand Wisata,
                Bekasi-Jawa Barat 17510
              </FooterText>
            </FooterLinkItems>
          </FooterLinksWrapper>
        </FooterLinksContainer>
        <WebsiteRights className="footer-webrights-wrapper">
          <WebRightSbl>
            Copyright © {new Date().getFullYear()}{" "}
            <strong>SAYAP BUANA LOGISTIK</strong>
          </WebRightSbl>
          <WebRightSeparator>|</WebRightSeparator>
          <WebRightAlas>
            design by
            <FooterLinkA href="https://alasgro.com">Alasgro</FooterLinkA>
          </WebRightAlas>
        </WebsiteRights>
      </FooterWrapper>
    </FooterContainer>
  );
};

export default Footer;
