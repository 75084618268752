/**
 *
 * @param {String} hash
 * @param {Number} YOffset
 * @returns {Number} Y Coordinate of element.
 */
const getYCoordinate = (hash, YOffset = 0) => {
  let htmlElement = document.getElementById(hash);

  if (htmlElement) {
    return htmlElement.getBoundingClientRect().top + window.scrollY + YOffset;
  } else {
    return 0;
  }
};

const getCountryCodeByIp = (callback) => {
  fetch('http://ip-api.com/json/')
    .then((res) => res.json())
    .then((result) => {
      const { countryCode } = result;
      callback(countryCode.toLowerCase() === 'id' ? 'id' : 'en');
    })
    .catch((err) => console.log(err));
};

const Utils = {
  getYCoordinate,
  getCountryCodeByIp,
};

export default Utils;
