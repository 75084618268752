import React from "react";
import ConteinerImg1 from "../../images/Container/container.png";
import ConteinerImg2 from "../../images/Container/container40ftc.png";
// import ConteinerImg3 from '../../images/Container/containergreen.png';
import { TransportLautH1 } from "./TransportLautElements";
import "./TransportLaut.css";

import CargoTrans from "./TransportLaut.translation.json";
import { LangContext } from "../../Context/lang.context";

const TransportLaut = () => {
  const { language } = React.useContext(LangContext);
  return (
    <>
      <div className="services-section">
        <div className="services-heading">
          <TransportLautH1>{CargoTrans[language].header}</TransportLautH1>
        </div>

        <div className="services">
          <div className="services-cell">
            <img alt="" src={ConteinerImg1} className="services-cell_img"></img>
            <div className="services-cell_text">
              <div>
                <h2 className="h2">{CargoTrans[language].contentOne.header}</h2>
                {/* <p className="ptag">
                  {CargoTrans[language].contentOne.externalDim} : L 20ft, W 8ft,
                  H 8.6ft <br />
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;6.058 x 2.438
                  x 2.591 m
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentOne.internalDim} : 5.919 x 2.340
                  x 2.380 m
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentOne.totalMetric} : 33 M
                  <sup>3</sup>
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentOne.NettWeight} : 22.1 Ton
                </p> */}
              </div>
            </div>
          </div>

          <div className="services-cell">
            <img alt="" src={ConteinerImg2} className="services-cell_img"></img>
            <div className="services-cell_text">
              <div>
                <h2 className="h2">{CargoTrans[language].contentTwo.header}</h2>
                {/* <p className="ptag">
                  {CargoTrans[language].contentTwo.externalDim} : L 40ft, W 8ft,
                  H 8.6ft <br />
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;12.192 x 2.438
                  x 2.591 m
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentTwo.internalDim} : 5.919 x 2.340
                  x 2.380 m
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentTwo.totalMetric} : 67.3 M
                  <sup>3</sup>
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentTwo.NettWeight} : 27.396 Ton
                </p> */}
              </div>
            </div>
          </div>

          {/* <div className="services-cell">
            <img alt="" src={ConteinerImg3} className="services-cell_img"></img>
            <div className="services-cell_text">
              <div>
                <h2 className="h2">
                  {CargoTrans[language].contentThree.header}
                </h2>
                <p className="ptag">
                  {CargoTrans[language].contentThree.externalDim} : L 40ft, W
                  8ft, H 9.6ft <br />
                  &emsp;&emsp;&emsp;&emsp;&emsp;&emsp;&nbsp;&nbsp;12.192 x 2.438
                  x 2.926 m
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentThree.internalDim} : 12.045 x
                  2.347 x 2.684 m
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentThree.totalMetric} : 76 M
                  <sup>3</sup>
                </p>
                <p className="ptag">
                  {CargoTrans[language].contentThree.NettWeight} : 29.6 Ton
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </>
  );
};

export default TransportLaut;
