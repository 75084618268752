import React from "react";
import {
  SidebarContainer,
  Icon,
  CloseIcon,
  SidebarWrapper,
  SidebarMenu,
  SidebarLink,
  SidebarNoLink,
  SidebarLinkParent,
  SidebarLinkChild,
} from "./SidebarElements";

import { ReactComponent as LangTrans } from "../../images/language-solid.svg";
import { LangContext } from "../../Context/lang.context";
import SidebarTrans from "./Sidebar.translation.json";

const Sidebar = ({ isOpen, toggle }) => {
  const { language, setLanguage } = React.useContext(LangContext);

  const onLangClick = () => {
    setLanguage(language === "id" ? "en" : "id");
  };

  return (
    <SidebarContainer isOpen={isOpen} onClick={toggle}>
      <Icon onClick={toggle}>
        <CloseIcon />
      </Icon>
      <SidebarWrapper>
        <SidebarMenu>
          <SidebarNoLink onClick={onLangClick}>
            <LangTrans fill="#ffffff" height="24px" />
          </SidebarNoLink>
          <SidebarLink to="/" onClick={toggle}>
            <strong>{SidebarTrans[language].home}</strong>
          </SidebarLink>
          <SidebarLink to="/About" onClick={toggle}>
            <strong>{SidebarTrans[language].about}</strong>
          </SidebarLink>

          <SidebarLinkParent>
            <strong>{SidebarTrans[language].services}</strong>
          </SidebarLinkParent>
          <SidebarLinkChild to="/TransportDarat" onClick={toggle}>
            <strong>{SidebarTrans[language].land}</strong>
          </SidebarLinkChild>
          <SidebarLinkChild to="/TransportLaut" onClick={toggle}>
            <strong>{SidebarTrans[language].sea}</strong>
          </SidebarLinkChild>
          <SidebarLinkChild to="/TransportUdara" onClick={toggle}>
            <strong>{SidebarTrans[language].air}</strong>
          </SidebarLinkChild>
          <SidebarLinkChild to="/Warehouse" onClick={toggle}>
            <strong>{SidebarTrans[language].warehouse}</strong>
          </SidebarLinkChild>
          <SidebarLinkChild to="/Cargo" onClick={toggle}>
            <strong>{SidebarTrans[language].cargo}</strong>
          </SidebarLinkChild>

          <SidebarLinkParent>
            <strong>{SidebarTrans[language].contact}</strong>
          </SidebarLinkParent>
          <SidebarLinkChild
            to={{
              pathname: "Warehouse",
              hash: "contact-us-warehouse",
            }}
            onClick={toggle}
          >
            <strong>{SidebarTrans[language].customer}</strong>
          </SidebarLinkChild>
          <SidebarLinkChild
            to={{
              pathname: "TransportDarat",
              hash: "contact-us-transporter",
            }}
            onClick={toggle}
          >
            <strong>{SidebarTrans[language].transporter}</strong>
          </SidebarLinkChild>
        </SidebarMenu>
      </SidebarWrapper>
    </SidebarContainer>
  );
};

export default Sidebar;
