export const MenuItems2 = [
  {
    title: {
      id: 'Untuk Customer',
      en: 'For Customer',
    },
    path: './Warehouse',
    hash: 'contact-us-warehouse',
    cName: 'dropdown-link',
  },
  {
    title: {
      id: 'Untuk Transporter',
      en: 'For Transporter',
    },
    path: './TransportDarat',
    hash: 'contact-us-transporter',
    cName: 'dropdown-link',
  },
];
