import React from 'react';
import Image from '../../images/warehousebg.jpg';
import {
  WarehouseContainer,
  WarehouseBg,
  WarehouseH1,
  WarehouseContent,
  WarehouseP,
  ImageBg,
  // PartnerContainer,
  // PartnerWrapper,
  // PartnerHead,
  Column2,
  // Img,
  // Red,
} from './WarehouseElements';
import './Warehouse.css';
// import Dekoruma from '../../images/PartnerImages/dekoruma.jpg';
// import Derochi from '../../images/PartnerImages/derochi.jpg';
// import MrDIY from '../../images/PartnerImages/diy2.jpg';
// import MazzBrim from '../../images/PartnerImages/mazbrim.jpg';
// import Bstrans from '../../images/PartnerImages/bstrans.jpg';
// import Hokindo from '../../images/PartnerImages/hokindo.jpg';
// import Primex from '../../images/PartnerImages/primex2.jpg';
// import Sds from '../../images/PartnerImages/sds.jpg';
import {
  FormContainer,
  FormH1,
  FormInput,
  FormLabel,
  FormWrap,
  FormButton,
  FormBg,
  Formf,
  FormLabelChild,
  FormRow,
} from './FormElements';
import FormBackground from '../../images/FormBgW.jpg';

import WarehouseTrans from './Warehouse.translation.json';
import { LangContext } from '../../Context/lang.context';

const Warehouse = () => {
  const { language } = React.useContext(LangContext);
  return (
    <>
      <WarehouseContainer>
        <WarehouseBg>
          <ImageBg src={Image} type="images/jpg" />
        </WarehouseBg>
        <WarehouseContent>
          <WarehouseH1>{WarehouseTrans[language].coverHead}</WarehouseH1>
          <WarehouseP>{WarehouseTrans[language].coverDesc}</WarehouseP>
          <WarehouseP>{WarehouseTrans[language].coverDesc1}</WarehouseP>
          <WarehouseP>{WarehouseTrans[language].coverDesc2}</WarehouseP>
          <WarehouseP>{WarehouseTrans[language].coverDesc3}</WarehouseP>
        </WarehouseContent>
      </WarehouseContainer>

      {/* <PartnerContainer>
        <PartnerWrapper>
          <PartnerHead className="lineAbout1">
            {WarehouseTrans[language].partner}{' '}
            <Red className="lineAbout2">{WarehouseTrans[language].our}</Red>
          </PartnerHead>
        </PartnerWrapper>
        <div className="galleryW">
          <div className="imageW">
            <span>
              <Img src={Dekoruma} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={Derochi} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={MrDIY} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={Sds} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={Bstrans} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={Hokindo} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={MazzBrim} type="images/jpg" />
            </span>
          </div>
          <div className="imageW">
            <span>
              <Img src={Primex} type="images/jpg" />
            </span>
          </div>
        </div>
      </PartnerContainer> */}

      <FormContainer id="contact-us-warehouse">
        <FormWrap className="row">
          <FormBg>
            <ImageBg src={FormBackground} type="images/jpg" />
          </FormBg>

          <FormRow>
            <FormH1 className="lineAbout3">
              {WarehouseTrans[language].contact}
            </FormH1>
            <Column2>
              <Formf
                className="box-sizing"
                name="contact-us-form-warehouse"
                method="POST"
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="contact-us-form-warehouse"
                />
                <FormLabel htmlFor="for">
                  <strong>{WarehouseTrans[language].formName}</strong>
                </FormLabel>
                <FormInput
                  type="text"
                  id="clientName"
                  name="clientName"
                  placeholder={WarehouseTrans[language].phName}
                  required
                />
                <FormLabel htmlFor="for">
                  <strong>{WarehouseTrans[language].formCompanyName}</strong>
                </FormLabel>
                <FormInput
                  type="text"
                  id="clientCompanyName"
                  name="clientCompanyName"
                  placeholder={WarehouseTrans[language].phCompanyName}
                />
                <FormLabel htmlFor="for">
                  <strong>{WarehouseTrans[language].formPhone}</strong>
                </FormLabel>
                <FormInput
                  type="text"
                  id="clientPhone"
                  name="clientPhone"
                  placeholder={WarehouseTrans[language].phPhone}
                  required
                />
                <FormLabel htmlFor="for">
                  <strong>{WarehouseTrans[language].formOriginCity}</strong>
                </FormLabel>
                <FormInput
                  type="text"
                  id="clientCity"
                  name="clientCity"
                  placeholder={WarehouseTrans[language].phOriginCity}
                  required
                />
                <FormLabel htmlFor="for">
                  <strong>{WarehouseTrans[language].formDestCity}</strong>
                </FormLabel>
                <FormInput
                  type="text"
                  id="clientDestination"
                  name="clientDestination"
                  placeholder={WarehouseTrans[language].phDestCity}
                  required
                />
                <FormLabel htmlFor="for">
                  <strong>{WarehouseTrans[language].detailShipment}</strong>
                </FormLabel>
                <FormLabelChild>
                  <strong>{WarehouseTrans[language].formKindOfGoods}</strong>
                </FormLabelChild>
                <FormInput
                  type="text"
                  id="clientGoods"
                  name="clientGoods"
                  placeholder={WarehouseTrans[language].phKindOfGoods}
                  required
                />
                <FormLabelChild>
                  <strong>{WarehouseTrans[language].formTonnage}</strong>
                </FormLabelChild>
                <FormInput
                  type="text"
                  id="clientWeight"
                  name="clientWeight"
                  placeholder={WarehouseTrans[language].phTonnage}
                />
                <FormLabelChild>
                  <strong>{WarehouseTrans[language].formVolume}</strong>
                </FormLabelChild>
                <FormInput
                  type="text"
                  id="clientVolume"
                  name="clientVolume"
                  placeholder={WarehouseTrans[language].phVolume}
                />
                <FormButton type="submit">
                  <strong>{WarehouseTrans[language].formSubmit}</strong>
                </FormButton>
              </Formf>
            </Column2>
          </FormRow>
        </FormWrap>
      </FormContainer>
    </>
  );
};

export default Warehouse;
