import styled from 'styled-components';
import { Link as LinkR } from 'react-router-dom';
// import { Link as LinkS } from 'react-scroll';

export const Nav = styled.nav`
    background: #fff;
    margin-top: 0;
    margin-left: auto;
    margin-right: auto;
    height: 80px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1rem;
    position: sticky;
    top: 0;
    z-index: 999;
    font-family: 'Kanit', sans-serif;


    @media screen and (max-width: 960px) {
        transition 0.8s all ease;
    } 
`;

export const NavbarContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 80px;
  z-index: 1;
  width: 100%;
  padding: 0 24px;
  max-width: 1140px;
`;

export const NavLogo = styled(LinkR)`
  color: #052e69;
  justify-self: flex-start;
  cursor: pointer;
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  margin-left: -90px;
  font-weight: bold;
  text-decoration: none;

  // @media screen and (max-width: 1920px) {
  //     margin-left: -140px;
  // }

  @media screen and (max-width: 780px) {
    margin-left: 0px;
  }

  @media screen and (max-width: 480px) {
    font-size: 1.1rem;
  }

  @media screen and (max-width: 320px) {
    font-size: 1rem;
  }

  @media screen and (min-width: 760px) {
    margin-left: 0px;
  }
`;

export const MobileIcon = styled.div`
  display: none;

  @media screen and (max-width: 768px) {
    display: block;
    position: absolute;
    top: 0;
    right: 0;
    transform: translate(-100%, 60%);
    font-size: 1.8rem;
    cursor: pointer;
    color: #052e69;
  }
`;

export const NavMenu = styled.ul`
  display: flex;
  align-items: center;
  list-style: none;
  text-align: center;
  margin-right: -22px;
  font-family: 'Kanit:wght@600', sans-serif;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const NavItem = styled.li`
  height: 80px;
`;

export const NavLinks = styled(LinkR)`
  color: #052e69;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #00a2ff;
  }
`;

export const NavNoLinks = styled.div`
  color: #052e69;
  display: flex;
  align-items: center;
  text-decoration: none;
  padding: 0 1rem;
  height: 100%;
  cursor: pointer;

  &.active {
    border-bottom: 3px solid #00a2ff;
  }
`;
