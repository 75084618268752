import React, { useContext, useState } from 'react';
import { FaBars } from 'react-icons/fa';
import {
  MobileIcon,
  Nav,
  NavbarContainer,
  NavItem,
  NavLinks,
  NavLogo,
  NavMenu,
  NavNoLinks,
} from './NavbarElements';
import LogoHeader from '../../images/logo.png';
import './Navbar.css';
import Dropdown from '../Dropdown/Dropdown';
import Dropdown2 from '../Dropdown/Dropdown2';

import { ReactComponent as LangTrans } from '../../images/language-solid.svg';
import { LangContext } from '../../Context/lang.context';
import NavbarTrans from './Navbar.translation.json';

const Navbar = ({ toggle }) => {
  const [dropdown, setDropdown] = useState(false);
  const [dropdown2, setDropdown2] = useState(false);

  const { setLanguage, language } = useContext(LangContext);
  const onLangClick = () => {
    setLanguage(language === 'id' ? 'en' : 'id');
  };

  // const handleClick = () => setClick(!click);

  const onMouseEnter = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(true);
    }
  };

  const onMouseLeave = () => {
    if (window.innerWidth < 960) {
      setDropdown(false);
    } else {
      setDropdown(false);
    }
  };

  const onMouseEnter2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(true);
    }
  };

  const onMouseLeave2 = () => {
    if (window.innerWidth < 960) {
      setDropdown2(false);
    } else {
      setDropdown2(false);
    }
  };

  return (
    <>
      <Nav>
        <NavbarContainer>
          <NavLogo to="/">
            <img src={LogoHeader} alt="" height="60px" />
            SAYAP BUANA LOGISTIK
          </NavLogo>
          <MobileIcon onClick={toggle}>
            <FaBars />
          </MobileIcon>
          <NavMenu>
            <NavItem>
              <NavLinks to="/" className="line">
                <strong>{NavbarTrans[language].home}</strong>
              </NavLinks>
            </NavItem>
            <NavItem>
              <NavLinks to="/About" className="line">
                <strong>{NavbarTrans[language].about}</strong>
              </NavLinks>
            </NavItem>
            <NavItem onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave}>
              <NavNoLinks className="line">
                <strong>{NavbarTrans[language].services}</strong>
              </NavNoLinks>
              {dropdown && <Dropdown />}
            </NavItem>
            <NavItem onMouseEnter={onMouseEnter2} onMouseLeave={onMouseLeave2}>
              <NavNoLinks className="line">
                <strong>{NavbarTrans[language].contact}</strong>
              </NavNoLinks>
              {dropdown2 && <Dropdown2 />}
            </NavItem>
            <NavItem
              style={{
                display: 'flex',
                alignItems: 'center',
                padding: '0 16px',
                cursor: 'pointer',
              }}
              onClick={onLangClick}
            >
              <LangTrans fill="#07306a" height="30px" />
            </NavItem>
          </NavMenu>
        </NavbarContainer>
      </Nav>
    </>
  );
};

export default Navbar;
