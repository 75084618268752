import React from 'react';
import Image from '../../images/udarabg.jpg';
import {
  UdaraContainer,
  UdaraBg,
  ImageBg,
  UdaraContent,
  UdaraH1,
  Yellow,
  UdaraP,
} from './TransportUdaraElements';

import UdaraTrans from './TransportUdara.translation.json';
import { LangContext } from '../../Context/lang.context';

const TransportUdara = () => {
  const { language } = React.useContext(LangContext);
  return (
    <>
      <UdaraContainer>
        <UdaraBg>
          <ImageBg src={Image} type="images/jpg" />
        </UdaraBg>
        <UdaraContent>
          <UdaraH1>
            {UdaraTrans[language].coverHeadOne}{' '}
            <Yellow>{UdaraTrans[language].coverHeadTwo}</Yellow>
          </UdaraH1>
          <UdaraP>{UdaraTrans[language].pOne}</UdaraP>
          <UdaraP>
            <Yellow>{UdaraTrans[language].pTwo}</Yellow>
          </UdaraP>
        </UdaraContent>
      </UdaraContainer>
    </>
  );
};

export default TransportUdara;
