import styled from "styled-components";

export const FormContainer =styled.div`
    height: auto;
    padding: 0px;
    color: #d11329;
    position: relative;
    background-size: cover;
    background-repeat: no-repeat;
    background-attachment: fixed;

    @media screen and (max-width: 768px) {
        padding: 100px 0;
    }
`;
 

export const FormBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hiden;
`;

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #232a34;
`;


export const FormWrap = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;

    @media screen and (max-width: 400px) {
        height: 80%;
    }
`;

export const Formf = styled.form`
    background: #fff;
    height: auto;
    width: 100%;
    z-index: 1;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-direction: column;
    flex-direction: column;
    margin: 0 auto;
    padding: 30px;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
    position: relative;


    @media screen and (max-width: 400px) {
        padding: 32px 32px;
    }
`;

export const FormRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: top;
    grid-template-areas: ${({imgStart}) => imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 768px) {
        grid-template-areas: ${({imgStart}) => imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    }
`;

export const FormH1 = styled.h1`
    color: #fff;
    font-size: 68px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    left: 0;
    top: 50px;
    
    @media screen and (max-width: 768px) {
        font-size: 48px;
        top: -40px;
        Left: 80px;
        margin-bottom: -60px;
    }

    @media screen and (max-width: 695px) {
        font-size: 42px;
        top: -40px;
        Left: 80px;
        margin-bottom: -60px;
    }

    @media screen and (max-width: 615px) {
        font-size: 33px;
        top: -40px;
        Left: 52px;
        margin-bottom: -90px;
    }

`;

export const FormLabel = styled.label`
    margin-bottom: 4px;
    font-size: 14px;
    color: #052e69;
    font-family: 'Roboto', sans-serif;
`;

export const FormInput = styled.input`
    padding: 9px 12px;
    margin-bottom: 22px;
    border: 1px solid rgba(0,0,0,.125);
    border-radius: 0.25rem;
`;

export const FormButton = styled.button`
    background: #052e69;
    padding: 9px 0;
    border: none;
    border-radius: 4px;
    color: #fff;
    font-size: 18px;
    cursor: pointer;
    align-item: right;
    font-family: 'Kanit', sans-serif;

    &:hover {
        transition: all 0.2s ease-in-out;
        background: ${({ primary }) => (primary ? '#fff' : '#30c1ff')};
        color: #052e69;
    }
`;

