export const MenuItems = [
  {
    title: {
      en: "Inland Transport",
      id: "Transportasi Darat",
    },
    path: "./TransportDarat",
    cName: "dropdown-link",
  },
  {
    title: {
      en: "Oversea Transport",
      id: "Transportasi Laut",
    },
    path: "./TransportLaut",
    cName: "dropdown-link",
  },
  {
    title: {
      en: "Air Freight",
      id: "Transportasi Udara",
    },
    path: "./TransportUdara",
    cName: "dropdown-link",
  },
  {
    title: {
      en: "Warehouse Management",
      id: "Manajemen Pergudangan",
    },
    path: "./Warehouse",
    cName: "dropdown-link",
  },
  {
    title: {
      en: "Cargo",
      id: "Kargo",
    },
    path: "./Cargo",
    cName: "dropdown-link",
  },
];
