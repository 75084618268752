import React from "react";
import Image from "../../images/peta.jpg";
import "./TransportDarat.css";
import TruckCddLong from "../../images/Armada/TruckCddLong.jpg";
import TruckCddStandar from "../../images/Armada/TruckCddStandar.jpg";
import TruckFusoLong from "../../images/Armada/TruckFusoLong.jpg";
import TruckFusoStandar from "../../images/Armada/TruckFusoStandar.jpg";
import TruckTronton from "../../images/Armada/TruckTrontona.jpg";
import {
  FormContainer,
  FormH1,
  FormInput,
  FormLabel,
  FormWrap,
  FormButton,
  FormBg,
  Formf,
  FormRow,
} from "./FormElements";
import FormBackground from "../../images/FormBgTD.jpg";
import {
  ArmadaContainer,
  ArmadaHead,
  ArmadaWrapper,
  DaratBg,
  DaratContainer,
  DaratContent,
  DaratH1,
  DaratP,
  ImageBg,
  Img,
  Column2,
  Red,
  DaratRow,
  DaratBox,
  DaratList,
} from "./TransportDaratElements";

import TransportDaratTrans from "./TransportDarat.translation.json";
import { LangContext } from "../../Context/lang.context";

const TransportDarat = ({
  description1,
  description2,
  description3,
  description4,
}) => {
  const { language } = React.useContext(LangContext);
  return (
    <>
      <DaratContainer>
        <DaratBg>
          <ImageBg src={Image} type="images/jpg" />
        </DaratBg>

        <DaratContent>
          <DaratRow>
            <DaratH1>{TransportDaratTrans[language].coverHead}</DaratH1>

            <DaratBox>
              <DaratP>{TransportDaratTrans[language].coverSubHead}</DaratP>
              <DaratList>{TransportDaratTrans[language].optionOne}</DaratList>
              <DaratList>{TransportDaratTrans[language].optionTwo}</DaratList>
              <DaratList>{TransportDaratTrans[language].optionThree}</DaratList>
              <DaratList>{TransportDaratTrans[language].optionFour}</DaratList>
              {/* <DaratList>{description4}</DaratList> */}
            </DaratBox>
          </DaratRow>
        </DaratContent>
      </DaratContainer>

      <ArmadaContainer>
        <ArmadaWrapper>
          <ArmadaHead className="lineAbout1">
            {TransportDaratTrans[language].fleet}{" "}
            <Red className="lineAbout2">
              {TransportDaratTrans[language].our}
            </Red>
          </ArmadaHead>
        </ArmadaWrapper>
        <div className="gallery">
          <div className="image" data-name="cdd">
            <span>
              <Img src={TruckCddStandar} type="images/jpg" />
            </span>
          </div>
          <div className="image" data-name="cdd">
            <span>
              <Img src={TruckCddLong} type="images/jpg" />
            </span>
          </div>
          <div className="image" data-name="fuso">
            <span>
              <Img src={TruckFusoStandar} type="images/jpg" />
            </span>
          </div>
          <div className="image" data-name="fuso">
            <span>
              <Img src={TruckFusoLong} type="images/jpg" />
            </span>
          </div>
          <div className="image" data-name="tronton">
            <span>
              <Img src={TruckTronton} type="images/jpg" />
            </span>
          </div>
        </div>
      </ArmadaContainer>

      <FormContainer>
        <FormWrap className="row">
          <FormBg>
            <ImageBg src={FormBackground} type="images/jpg" />
          </FormBg>

          <FormRow id="contact-us-transporter">
            <FormH1 className="lineAbout3">
              {TransportDaratTrans[language].contact}
            </FormH1>
            <Column2>
              <Formf
                className="box-sizing"
                name="contact-us-form-transporter"
                method="POST"
              >
                <input
                  type="hidden"
                  name="form-name"
                  value="contact-us-form-transporter"
                />
                <FormLabel htmlFor="for">
                  {TransportDaratTrans[language].formName}
                </FormLabel>
                <FormInput
                  type="text"
                  id="transporterName"
                  name="transporterName"
                  placeholder={TransportDaratTrans[language].phName}
                  required
                />
                <FormLabel htmlFor="for">
                  {TransportDaratTrans[language].formCompanyName}
                </FormLabel>
                <FormInput
                  type="text"
                  id="transporterCompanyName"
                  name="transporterCompanyName"
                  placeholder={TransportDaratTrans[language].phCompanyName}
                />
                <FormLabel htmlFor="for">
                  {TransportDaratTrans[language].formPhone}
                </FormLabel>
                <FormInput
                  type="text"
                  id="transporterPhone"
                  name="transporterPhone"
                  placeholder={TransportDaratTrans[language].phPhone}
                  required
                />
                <FormLabel htmlFor="for">
                  {TransportDaratTrans[language].formOrigin}
                </FormLabel>
                <FormInput
                  type="text"
                  id="transporterBaseCity"
                  name="transporterBaseCity"
                  placeholder={TransportDaratTrans[language].phOrigin}
                  required
                />
                <FormButton type="submit" id="transporterSubmit">
                  <strong>{TransportDaratTrans[language].formSubmit}</strong>
                </FormButton>
              </Formf>
            </Column2>
          </FormRow>
        </FormWrap>
      </FormContainer>
    </>
  );
};

export default TransportDarat;
