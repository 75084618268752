import styled from 'styled-components';


export const UdaraContainer = styled.div`
    // background: #0c0c0c;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px;
    height: 650px;
    position: fix;
    z-index: -1;
    position: relative;

    :before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background: linear-gradient(
            to top left,
            rgba(0, 0, 0, 0.2) 0%,
            rgba(0, 0, 0, 0.1) 0%
          ),
          linear-gradient(to bottom left, rgba(5, 46, 105) 0%, transparent 100%);
        z-index: 2;
    }
    
`;

export const UdaraBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hiden;
`;

export const ImageBg = styled.img`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #e9edf5;
`;

export const UdaraContent = styled.div`
    z-index: 3;
    
    // max-width: 1200px;
    // position: absolute;
    // padding: 0;
    // display: flex;
    // flex-direction: column;
    // align-items: center;

`;

export const UdaraH1 = styled.h1`
    color: #fff;
    font-size: 68px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    

    @media screen and (max-width: 760px) {
        font-size: 40px;
    }

    @media screen and (max-width: 480px) {
        font-size: 32px;
    }
`;

export const UdaraP = styled.p`
    margin-top: 20px;
    color: #fff;
    font-size: 24px;
    line-height: 12px;
    text-align: center;
    font-family: 'Roboto', sans-serif;

    @media screen and (max-width: 760px) {
        font-size: 24px;
        line-height: 24px;
    }

    @media screen and (max-width: 480px) {
        font-size: 18px;
    }
`;

export const Yellow = styled.span`
    color: #f7d805;
`;