import styled from 'styled-components';

export const WarehouseContainer = styled.div`
  background: #0c0c0c;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;
  mask-image: linear-gradient(to bottom, rgba(0, 0, 0, 1) 85%, rgba(0, 0, 0, 0));

  :before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        180deg,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.5) 0%
      ),
      linear-gradient(180deg, rgba(5, 46, 105) 0%, transparent 100%);
    z-index: 2;
  }

  @media screen and (width: 915px) {
    height: 200vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 914px) {
    height: 150vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 912px) {
    height: 80vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 896px), (height: 414px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 851px), (height: 393px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 844px), (height: 390px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 812px) {
    height: 190vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 740px), (height: 360px) {
    height: 200vh;
    margin-bottom: -40vh;
  }

  @media screen and (width: 736px) {
    height: 170vh;
    margin-bottom: -60px;
  }

  @media screen and (width: 720px) {
    height: 120vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 712px) {
    height: 100vh;
    margin-bottom: -30vh;
  }

  @media screen and (width: 658px), (height: 320px) {
    height: 200vh;
    margin-bottom: -50vh;
  }

  @media screen and (width: 667px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 640px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 568px) {
    height: 215vh;
    margin-bottom: -40vh;
  }

  @media screen and (width: 540px) {
    height: 100vh;
    margin-bottom: auto;
  }
`;

export const WarehouseBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hiden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const WarehouseContent = styled.div`
  z-index: 3;
  max-width: 3840px;
  position: relative;
  margin-top: 0px;
  padding: 8px 2px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: 'Kanit', sans-serif;

  @media screen and (max-width: 480px) {
    margin-top: -40px;
  }
`;

export const WarehouseH1 = styled.h1`
  color: #fff;
  font-size: 60px;
  text-align: left;
  position: relative;

  @media screen and (max-width: 3840px) {
    font-size: 110px;
  }

  @media screen and (max-width: 2048px) {
    font-size: 60px;
  }

  @media screen and (max-width: 1920px) {
    font-size: 60px;
  }

  @media screen and (max-width: 760px) {
    font-size: 40px;
  }

  @media screen and (max-width: 480px) {
    font-size: 38px;
  }

  @media screen and (max-width: 320px) {
    margin-top: 40px;
  }
`;

export const WarehouseP = styled.p`
  margin-top: 20px;
  color: #fff;
  font-size: 20px;
  text-align: left;
  max-width: 800px;
  line-height: 32px;

  @media screen and (max-width: 3840px) {
    font-size: 60px;
    max-width: 1800px;
    line-height: 82px;
  }

  @media screen and (max-width: 2048px) {
    font-size: 20px;
    max-width: 800px;
    line-height: 28px;
  }

  @media screen and (max-width: 1920px) {
    font-size: 24px;
  }

  @media screen and (max-width: 760px) {
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
    line-height: 24px;
  }
`;

// ---------------------------------- PARTNER PAGE ----------------------------------

export const PartnerContainer = styled.div`
  color: #d11329;
  background: #fff;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    margin-top: 22px;
  }
`;

export const PartnerWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // @media screen and (max-width: 3840px) {
  //     margin-top: 1560px;
  // }

  // @media screen and (max-width: 2560px) {
  //     margin-top: 800px;
  // }

  // @media screen and (max-width: 2048px) {
  //     margin-top: 360px;
  // }

  // @media screen and (max-width: 1368px) {
  //     margin-top: 200px;
  // }

  // @media screen and (max-width: 1366px) {
  //     margin-top: 100px;
  // }

  // @media screen and (max-width: 1280px) {
  //     margin-top: 60px;
  // }

  // @media screen and (max-width: 1180px) {
  //     margin-top: 120px;
  // }

  // @media screen and (max-width: 1100px) {
  //     margin-top: 160px;
  // }

  // @media screen and (max-width: 1024px) {
  //     margin-top: 300px;
  // }

  // @media screen and (max-width: 980px) {
  //     margin-top: 200px;
  // }

  // @media screen and (max-width: 912px) {
  //     margin-top: 890px;
  // }

  // @media screen and (max-width: 820px) {
  //     margin-top: 690px;
  // }

  // @media screen and (max-width: 780px) {
  //     margin-top: 190px;
  // }

  // @media screen and (max-width: 770px) {
  //     margin-top: 100px;
  // }

  // @media screen and (max-width: 768px) {
  //     margin-top: 420px;
  // }

  // @media screen and (max-width: 720px) {
  //     margin-top: 20px;
  // }

  // @media screen and (max-width: 712px) {
  //     margin-top: 530px;
  // }

  // @media screen and (max-width: 540px) {
  //     margin-top: 130px;
  // }

  // @media screen and (max-width: 480px) {
  //     margin-top: 130px;
  // }

  // @media screen and (max-width: 414px) {
  //     margin-top: 290px;
  // }

  // @media screen and (max-width: 412px) {
  //     margin-top: 310px;
  // }

  // @media screen and (max-width: 375px) {
  //     margin-top: 200px;
  // }

  // @media screen and (max-width: 360px) {
  //     margin-top: 120px;
  // }

  @media screen and (max-width: 320px) {
    margin-top: 40px;
  }
`;

export const Column2 = styled.div`
  margin-botom: 0px;
  padding: 35px;
  grid-area: col2;
`;

export const Img = styled.img`
  max-width: 80%;
  pading-right: 0;
  height: auto;

  margin-left: auto;
  margin-right: auto;
`;

export const PartnerHead = styled.h1`
  // margin-top: 220px;
  position: relative;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  color: #052e69;
  font-family: 'Roboto', sans-serif;

  @media screen and (max-width: 3840px) {
    font-size: 132px;
  }

  @media screen and (max-width: 2560px) {
    font-size: 102px;
  }

  @media screen and (max-width: 2048px) {
    font-size: 62px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 52px;
  }

  @media screen and (max-width: 1100px) {
    font-size: 42px;
    margin-top: auto;
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
    margin-top: 0px;
  }
`;

export const Red = styled.span`
  color: #cb0c0d;
`;

export const homeObjTwo = {
  id: '',
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headLine1: 'PARTNER KAMI',
  headLine3: 'HUBUNGI KAMI',
  imgStart: true,
  alt: '',
  dark: false,
  primary: false,
  darkText: true,
};
