import styled from 'styled-components';

export const AboutContainer = styled.div`
    background: #fff;
    display: flex;
    justify-content: center;
    align-items: top;
    padding: 0 30px;
    height: 80vh;
    position: relative;
    z-index: -1;

    @media screen and (width: 915px) {
        height: 200vh;
        margin-bottom: -20vh;
    }

    @media screen and (width: 914px) {
        height: 150vh;
        margin-bottom: -20vh;
    }

    @media screen and (width: 912px) {
        height: 80vh;
        margin-bottom: -20vh;
    }

    @media screen and (width: 896px), (height: 414px) {
        height: 200vh;
        margin-bottom: -60vh;
    }

    @media screen and (width: 851px), (height: 393px) {
        height: 200vh;
        margin-bottom: -60vh;
    }

    @media screen and (width: 844px), (height: 390px) {
        height: 200vh;
        margin-bottom: -60vh;
    }

    @media screen and (width: 812px) {
        height: 190vh;
        margin-bottom: -20vh;
    }

    @media screen and (width: 740px), (height: 360px) {
        height: 200vh;
        margin-bottom: -40vh;
    }

    @media screen and (width: 736px) {
        height: 170vh;
        margin-bottom: -60px;
    }

    @media screen and (width: 720px) {
        height: 120vh;
        margin-bottom: -20vh;
    }

    @media screen and (width: 712px) {
        height: 100vh;
        margin-bottom: -30vh;
    }

    @media screen and (width: 658px), (height: 320px) {
        height: 200vh;
        margin-bottom: -50vh;
    }

    @media screen and (width: 667px) {
        height: 200vh;
        margin-bottom: -60vh;
    }

    @media screen and (width: 640px) {
        height: 200vh;
        margin-bottom: -60vh;
    }

    @media screen and (width: 568px) {
        height: 215vh;
        margin-bottom: -40vh;
    }

    @media screen and (width: 540px) {
        height: 100vh;
        margin-bottom: auto;
    }

`;

export const AboutBg = styled.div`
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hiden;

    @media screen and (max-width: 1368px) {
        height: auto;
    }

    @media screen and (max-width: 1280px) {
        height: 90%;
    }

    @media screen and (max-width: 1024px) {
        height: 80%;
    }


    //Surface
    @media screen and (max-width: 912px) {
        height: 53%;
    }

    //Ipad Air
    @media screen and (max-width: 820px) {
        height: 60%;
    }

    @media screen and (max-width: 780px) {
        height: 100%;
    }

    @media screen and (max-width: 768px) {
        height: 60%;
    }

    //Galaxy Tab
    @media screen and (max-width: 712px) {
        height: 50%;
    }

    //Surface Duo
    @media screen and (max-width: 540px) {
        height: 80%;
    }
    
    @media screen and (max-width: 480px) {
        height: 100%;
    }

    @media screen and (max-width: 415px) {
        height: 80%;
    }

    //Iphone XR
    @media screen and (max-width: 414px) {
        height: 67%;
    }
    
    //Iphone SE
    @media screen and (max-width: 375px) {
        height: 75%;
    }

    //Galaxy Note or S
    @media screen and (max-width: 360px) {
        height: 85%;
    }
`;

export const HeroBg = styled.div`
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #e9edf5;

    //Ipad Air
    @media screen and (max-width: 820px) {
        width: 100%;
        height: 100%;
    }

    @media screen and (max-width: 480px) {
        heigh: 50%;
    }
`;

export const Image1 = styled.img`
    width: 20%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #e9edf5;
`;

export const Image2 = styled.img`
    width: 20%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
    background: #e9edf5;
`;


export const AboutContent = styled.div`
    z-index: 3;
    max-width: 1200px;
    position: absolute;
    padding: 10px 20px 30px 500px;
    display: flex;
    flex-direction: column;
    align-items: center;
    top: auto;  

    @media screen and (max-width: 760px) {
        font-size: 40px;
        padding: 2px 10px 10px 10px;
        top: -1rem;  
        
    }
 
    @media screen and (max-width: 480px) {
        font-size: 32px;
    }

`;

export const Red = styled.span`
    color: #cb0c0d;
`;

export const AboutH1 = styled.h1`
    color: #052e69;
    font-size: 48px;
    text-align: center;
    font-family: 'Roboto', sans-serif;
    left: -150px;
    top: 2rem;;

    @media screen and (max-width: 1268px) {
        font-size: 48px;
        left: -126px;
    }

    @media screen and (max-width: 1082px) {
        font-size: 48px;
        left: -106px;
    }

    @media screen and (max-width: 968px) {
        font-size: 48px;
        left: -104px;
    }

    @media screen and (max-width: 886px) {
        font-size: 38px;
        left: -128px;
    }

    @media screen and (max-width: 818px) {
        font-size: 33px;
        left: -146px;
    }

    @media screen and (max-width: 776px) {
        font-size: 30px;
        left: -158px;
    }

    @media screen and (max-width: 760px) {
        font-size: 30px;
        left: 94px;
    }

    @media screen and (max-width: 480px) {
        font-size: 27px;
        left: 94px;
    }

    @media screen and (max-width: 375px) {
        font-size: 22px;
        left: 84px;
    }

    @media screen and (max-width: 360px) {
        font-size: 22px;
        left: 90px;
    }

    @media screen and (max-width: 320px) {
        font-size: 22px;
        left: 75px;
    }
    
`;

export const Content = styled.p`
    font-size: 28px;
    line-height: 44px;
    font-family: 'Kanit', sans-serif;
    margin-left: 15px;
    margin-top: 3rem;
    color: #052e69;

    @media screen and (max-width: 1280px) {
        font-size: 28px;
        margin-left: 24px;
        line-height: 44px;
    }

    @media screen and (max-width: 1268px) {
        font-size: 28px;
        margin-left: 24px;
        line-height: 44px;
    }

    @media screen and (max-width: 1168px) {
        font-size: 28px;
        margin-left: 8px;
        line-height: 38px;
    }

    @media screen and (max-width: 1130px) {
        font-size: 26px;
        margin-left: -9px;
        line-height: 42px;
    }


    @media screen and (max-width: 1068px) {
        font-size: 24px;
        margin-left: -22px;
        line-height: 34px;
    }

    @media screen and (max-width: 1024px) {
        font-size: 24px;
        margin-left: -45px;
        line-height: 34px;
    }

    @media screen and (max-width: 1000px) {
        font-size: 24px;
        margin-left: -55px;
        line-height: 34px;
    }

    @media screen and (max-width: 988px) {
        font-size: 22px;
        margin-left: -64px;
        line-height: 34px;
    }

    @media screen and (max-width: 948px) {
        font-size: 22px;
        margin-left: -82px;
        line-height: 34px;
    }

    @media screen and (max-width: 900px) {
        font-size: 22px;
        margin-left: -99px;
        line-height: 34px;
    }

    @media screen and (max-width: 868px) {
        font-size: 20px;
        margin-left: -104px;
        line-height: 34px;
    }

    @media screen and (max-width: 790px) {
        font-size: 18px;
        margin-left: -142px;
        line-height: 34px;
    }

    @media screen and (max-width: 778px) {
        font-size: 18px;
        margin-left: -148px;
        line-height: 34px;
    }

    @media screen and (max-width: 760px) {
        font-size: 18px;
        margin-left: 348px;
        line-height: 32px;
    }

    @media screen and (max-width: 712px) {
        font-size: 18px;
        margin-left: 328px;
        line-height: 18px;
    }

    @media screen and (max-width: 680px) {
        font-size: 18px;
        margin-left: 309px;
        line-height: 18px;
    }
    

    @media screen and (max-width: 660px) {
        font-size: 16px;
        margin-left: 299px;
        line-height: 18px;
    }

    @media screen and (max-width: 600px) {
        font-size: 16px;
        margin-left: 268px;
        line-height: 18px;
    }

    @media screen and (max-width: 578px) {
        font-size: 14px;
        margin-left: 256px;
        line-height: 16px;
    }

    @media screen and (max-width: 540px) {
        font-size: 14px;
        margin-left: 243px;
        line-height: 16px;
    }

    @media screen and (max-width: 414px) {
        font-size: 14px;
        margin-left: 195px;
        line-height: 16px;
    }

    @media screen and (max-width: 375px) {
        font-size: 14px;
        margin-left: 180px;
        line-height: 16px;
    }

    @media screen and (max-width: 360px) {
        font-size: 14px;
        margin-left: 180px;
        line-height: 16px;
    }

    @media screen and (max-width: 320px) {
        font-size: 12px;
        margin-left: 148px;
        line-height: 16px;
    }
`;

// ---------------------------------- VISI MISI PAGE ----------------------------------

export const VisiMisiContainer =styled.div`
    color: #d11329;
    background: #fff;
    margin-top: 20px;

    @media screen and (max-width: 768px) {
        margin-top: 20px;
    }
`;

export const VisiMisiWrapper = styled.div`
    display: grid;
    z-index: 1;
    height: auto;
    width: 100%;
    max-width: 1100px;
    margin-right: auto;
    margin-left: auto;
    padding: 0 24px;
    justify-content: center;

    @media screen and (max-width: 780px) {
        padding: 0;
    }
`;

export const VisiMisiRow = styled.div`
    display: grid;
    grid-auto-columns: minmax(auto, 1fr);
    align-items: center;
    grid-template-areas: ${({imgStart}) => imgStart ? `'col2 col1'` : `'col1 col2'`};

    @media screen and (max-width: 820px) {
        grid-template-areas: ${({imgStart}) => imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
    }
`;

export const Column1 = styled.div`
    margin-botom: 0px;
    padding: 15px;
    grid-area: col1;
    margin-top: 2rem;

    @media screen and (max-width: 3840px) {
        width: 390px;
        margin-left: -1100px;
        margin-top: 16rem;
    }

    @media screen and (max-width: 2560px) {
        width: 650px;
        margin-left: -560px;
    }

    @media screen and (max-width: 2048px) {
        width: 700px;
        margin-left: -270px;
        margin-top: 1rem;
    }

    @media screen and (max-width: 1920px) {
        width: 650px;
        margin-left: 0px;
        margin-top: 1rem;
    }

    @media screen and (max-width: 1368px) {
        width: 500px;
        margin-left: -70px;
    }

    @media screen and (max-width: 1280px) {
        width: 500px;
        margin-left: -400px;
    }

    @media screen and (max-width: 1138px) {
        width: auto;
        margin-left: 0px;
        margin-top: -10rem;
    }

    @media screen and (max-width: 820px) {
        width: auto;
        margin-left: 0px;
    }

    @media screen and (max-width: 780px) {
        width: auto;
        margin-left: 0px;
        margin-top: -10rem;
    }

    @media screen and (max-width: 480px) {
        width: auto;
        margin-left: 0px;
        margin-top: -2rem;
    }

    @media screen and (max-width: 414px) {
        width: auto;
        margin-left: 0px;
        margin-top: -10rem;
    }

    @media screen and (max-width: 375px) {
        width: auto;
        margin-left: 0px;
        margin-top: -7rem;
    }

    @media screen and (max-width: 360px) {
        width: auto;
        margin-left: 0px;
        margin-top: -5rem;
    }
`;

export const Column2 = styled.div`
    margin-botom: 0px;
    padding: 55px;
    grid-area: col2;
    margin-top: 1rem;

    @media screen and (max-width: 3840px) {
        width: auto;
        margin-right: -400px;
        padding: 75px;
        margin-top: 12rem;
    }

    @media screen and (max-width: 2560px) {
        width: auto;
        margin-right: -200px;
    }

    @media screen and (max-width: 2048px) {
        width: 700px;
        margin-right: -200px;
        padding: 95px;
    }

    @media screen and (max-width: 1920px) {
        margin-right: -150px;
    }

    @media screen and (max-width: 1368px) {
        width: 600px;
        margin-right: -100px;
    }

    @media screen and (max-width: 1280px) {
        width: 100px;
        padding: 75px;
        margin-right: 0px;
    }

    @media screen and (max-width: 1138px) {
        width: auto;
        padding: 0px 0px 55px 55px;
        margin-left: -320px;
    }

    @media screen and (max-width: 820px) {
        width: auto;
        padding: 55px;
        margin-left: -20px;
    }

    @media screen and (max-width: 780px) {
        width: auto;
        padding: 55px;
        margin-left: -20px;
    }

    @media screen and (max-width: 480px) {
        margin-bottom: -40px;
        width: auto;
        padding: 55px;
        margin-left: -20px;
        margin-top: 0rem;
    }
`;

export const ImgWrap = styled.div`
    max-width: 555px;
    height: 100%;

    // @media screen and (max-width: 3840px) {
    //     margin-top: 1510px;
    // }

    // @media screen and (max-width: 2560px) {
    //     margin-top: 790px;
    // }

    // @media screen and (max-width: 2048px) {
    //     margin-top: 420px;
    // }

    // @media screen and (max-width: 1920px) {
    //     margin-top: 420px;
    // }

    // @media screen and (max-width: 1368px) {
    //     margin-top: 120px;
    // }

    // @media screen and (max-width: 1280px) {
    //     margin-top: 70px;
    // }
`;

export const Img = styled.img`
    width: 100%;
    margin: 0 0 0 0;
    pading-right: 0; 

    @media screen and (max-width: 3840px) {
        width: 300%;
    }

    @media screen and (max-width: 2560px) {
        width: 160%;
    }

    @media screen and (max-width: 2048px) {
        width: 130%;
    }

    @media screen and (max-width: 1920px) {
        width: 110%;
    }

    @media screen and (max-width: 1368px) {
        width: 100%;
    }

    @media screen and (max-width: 1280px) {
        width: 100%;
    }

    @media screen and (max-width: 1138px) {
        width: 80%;
    }
    
    @media screen and (max-width: 820px) {
        width: 90%;
    }

    @media screen and (max-width: 780px) {
        width: 90%;
    }

    @media screen and (max-width: 480px) {
        width: 100%;
    }
`;


export const TextWrapper = styled.div`
    max-width: 540px;
    // padding-top: 130px;

    // @media screen and (max-width: 780px) {
    //     padding-top: 0;
    // }
`;

export const HeadingM = styled.h1`
    margin-bottom: 24px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #052e69;
    font-family: 'Kanit', sans-serif;

    @media screen and (max-width: 3840px) {
        font-size: 152px;
        width: 700px;
    }

    @media screen and (max-width: 2560px) {
        font-size: 98px;
    }

    @media screen and (max-width: 2048px) {
        font-size: 94px;
    }

    @media screen and (max-width: 1920px) {
        font-size: 84px;
    }

    @media screen and (max-width: 1368px) {
        font-size: 64px;
        width: 500px;
    }

    @media screen and (max-width: 1280px) {
        font-size: 54px;
        width: 300px;
    }

    @media screen and (max-width: 780px) {
        margin-bottom: 24px;
        font-size: 44px;
        line-height: 1.1;
        font-weight: 600;
        color: #052e69;
        font-family: 'Kanit', sans-serif;
    }

    @media screen and (max-width: 480px) {
        margin-bottom: 24px;
        font-size: 32px;
        line-height: 1.1;
        font-weight: 600;
        width: auto;
        color: #052e69;
        font-family: 'Kanit', sans-serif;
    }
`;

export const HeadingV = styled.h1`
    margin-bottom: 24px;
    margin-top: -100px;
    font-size: 48px;
    line-height: 1.1;
    font-weight: 600;
    color: #052e69;
    font-family: 'Kanit', sans-serif;

    @media screen and (max-width: 3840px) {
        font-size: 152px;
        width: 800px;
    }

    @media screen and (max-width: 2560px) {
        font-size: 98px;
    }

    @media screen and (max-width: 2048px) {
        font-size: 94px;
    }

    @media screen and (max-width: 1920px) {
        font-size: 84px;
    }

    @media screen and (max-width: 1368px) {
        font-size: 64px;
    }

    @media screen and (max-width: 1280px) {
        font-size: 54px;
    }

    @media screen and (max-width: 780px) {
        margin-bottom: 24px;
        margin-top: -100px;
        font-size: 44px;
        line-height: 1.1;
        width: auto;
        font-weight: 600;
        color: #052e69;
        font-family: 'Kanit', sans-serif;
    }

    @media screen and (max-width: 480px) {
        margin-bottom: 24px;
        margin-top: -100px;
        font-size: 32px;
        line-height: 1.1;
        width: auto;
        font-weight: 600;
        color: #052e69;
        font-family: 'Kanit', sans-serif;
    }
`;

export const Subtitle = styled.p`
    max-width: 1140px;
    margin-bottom: 0px;
    font-size: 22px;
    line-height: 28px;
    color: #052e69;
    font-family: 'Kanit', sans-serif;
    

    @media screen and (max-width: 3840px) {
        font-size: 66px;
        line-height: 82px;
        width: 1440px;
    }

    @media screen and (max-width: 2560px) {
        font-size: 48px;
        line-height: 56px;
        width: 940px;
    }

    @media screen and (max-width: 2048px) {
        font-size: 38px;
        line-height: 54px;
        width: 770px;
    }

    @media screen and (max-width: 1920px) {
        font-size: 32px;
        line-height: 40px;
        width: 640px;
    }

    @media screen and (max-width: 1368px) {
        font-size: 24px;
        line-height: 32px;
        width: 540px;
    }

    @media screen and (max-width: 1280px) {
        font-size: 24px;
        width: 540px;
    }

    @media screen and (max-width: 1180px) {
        font-size: 20px;
        line-height: 28px;
        width: 400px;
    }

    @media screen and (max-width: 1138px) {
        font-size: 20px;
        line-height: 28px;
        width: 300px;
    }

    @media screen and (max-width: 780px) {
        font-size: 20px;
        line-height: 28px;
        width: 440px;
    }

    @media screen and (max-width: 480px) {
        font-size: 16px;
        line-height: 18px;
        width: auto;
    }
`;


export const homeObjTwo = {
    id: 'visiMisi',
    lightBg: true,
    lightText: false,
    lightTextDesc: false,
    headLine1: 'Misi Kami', 
    headLine2: '❝ Visi Kami',
    description1: '● Sebagai solusi bagi transporter dalam efektifitas cash flow',
    description2: '● Menyediakan armada untuk kebutuhan logistik di seluruh Indonesia',
    description3: '● Sebagai penyedia layanan logistik terpercaya dengan lead time order yang cepat',
    // description4: '● Pemenuhan kebutuhan pengiriman diatas 95%',
    description5: 'Membantu transporter dalam pengembangan perusahaan dan fokus ke dalam investasi armada. juga menjadi penyedia layanan logistik terpercaya.',
    buttonLabel: 'Beli',
    imgStart: true,
    alt: 'visiMisi',
    dark: false, 
    primary: false,
    darkText: true 
};

export const AboutCard = styled.div`
    background: #fff;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
    border-radius: 8px;
    max-height: 940px;
    padding: 30px;
    box-shadow: 9px 16px 16px rgba(0, 0, 0, 0.3);
    transition: all 0.2s ease-in-out;
    margin-top: -50px;

    @media screen and (max-width: 3840px) {
        width: 1260px;
    }

    @media screen and (max-width: 2560px) {
        width: 990px;
    }

    @media screen and (max-width: 2048px) {
        width: 790px;
    }

    @media screen and (max-width: 1920px) {
        width: 660px;
    }

    @media screen and (max-width: 1368px) {
        width: 570px;
    }

    @media screen and (max-width: 1280px) {
        width: 550px;
    }

    @media screen and (max-width: 1138px) {
        width: 500px;
    }

    @media screen and (max-width: 1180px) {
        width: 500px;
    }

    @media screen and (max-width: 780px) {
        width: auto;
    }

    @media screen and (max-width: 480px) {
        width: auto;
    }

`;

// ----------------------------------- PARTNER ----------------------------------------------------

export const PartnerContainer = styled.div`
  color: #d11329;
//   background: #fff;
  margin-top: 60px;
  background: linear-gradient(
    180deg,
    rgba(233, 237, 245, 0.2) 0%,
    rgba(255, 255, 255, 0.3) 0%
  ),
  linear-gradient(180deg, rgba(233, 237, 245) 0%, transparent 15%);
    z-index: 2;

  @media screen and (max-width: 768px) {
    margin-top: 22px;
  }
`;

export const PartnerWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // @media screen and (max-width: 3840px) {
  //     margin-top: 1560px;
  // }

  // @media screen and (max-width: 2560px) {
  //     margin-top: 800px;
  // }

  // @media screen and (max-width: 2048px) {
  //     margin-top: 360px;
  // }

  // @media screen and (max-width: 1368px) {
  //     margin-top: 200px;
  // }

  // @media screen and (max-width: 1366px) {
  //     margin-top: 100px;
  // }

  // @media screen and (max-width: 1280px) {
  //     margin-top: 60px;
  // }

  // @media screen and (max-width: 1180px) {
  //     margin-top: 120px;
  // }

  // @media screen and (max-width: 1100px) {
  //     margin-top: 160px;
  // }

  // @media screen and (max-width: 1024px) {
  //     margin-top: 300px;
  // }

  // @media screen and (max-width: 980px) {
  //     margin-top: 200px;
  // }

  // @media screen and (max-width: 912px) {
  //     margin-top: 890px;
  // }

  // @media screen and (max-width: 820px) {
  //     margin-top: 690px;
  // }

  // @media screen and (max-width: 780px) {
  //     margin-top: 190px;
  // }

  // @media screen and (max-width: 770px) {
  //     margin-top: 100px;
  // }

  // @media screen and (max-width: 768px) {
  //     margin-top: 420px;
  // }

  // @media screen and (max-width: 720px) {
  //     margin-top: 20px;
  // }

  // @media screen and (max-width: 712px) {
  //     margin-top: 530px;
  // }

  // @media screen and (max-width: 540px) {
  //     margin-top: 130px;
  // }

  // @media screen and (max-width: 480px) {
  //     margin-top: 130px;
  // }

  // @media screen and (max-width: 414px) {
  //     margin-top: 290px;
  // }

  // @media screen and (max-width: 412px) {
  //     margin-top: 310px;
  // }

  // @media screen and (max-width: 375px) {
  //     margin-top: 200px;
  // }

  // @media screen and (max-width: 360px) {
  //     margin-top: 120px;
  // }

  @media screen and (max-width: 320px) {
    margin-top: 40px;
  }
`;

export const PartnerHead = styled.h1`
  // margin-top: 220px;
  position: relative;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  color: #052e69;
  font-family: 'Roboto', sans-serif;

  @media screen and (max-width: 3840px) {
    font-size: 132px;
  }

  @media screen and (max-width: 2560px) {
    font-size: 102px;
  }

  @media screen and (max-width: 2048px) {
    font-size: 62px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 52px;
  }

  @media screen and (max-width: 1100px) {
    font-size: 42px;
    margin-top: auto;
  }

  @media screen and (max-width: 480px) {
    font-size: 36px;
    margin-top: 0px;
  }
`;