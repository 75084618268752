import styled from "styled-components";

export const DaratContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 30px;
  height: 100vh;
  position: relative;
  z-index: 1;

  :before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: linear-gradient(
        to top left,
        rgba(0, 0, 0, 0.2) 0%,
        rgba(0, 0, 0, 0.1) 0%
      ),
      linear-gradient(to bottom left, rgba(5, 46, 105) 0%, transparent 70%);
    z-index: 2;
  }

  @media screen and (width: 915px) {
    height: 200vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 914px) {
    height: 150vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 912px) {
    height: 80vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 896px), (height: 414px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 851px), (height: 393px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 844px), (height: 390px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 812px) {
    height: 190vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 740px), (height: 360px) {
    height: 200vh;
    margin-bottom: -40vh;
  }

  @media screen and (width: 736px) {
    height: 170vh;
    margin-bottom: -60px;
  }

  @media screen and (width: 720px) {
    height: 120vh;
    margin-bottom: -20vh;
  }

  @media screen and (width: 712px) {
    height: 100vh;
    margin-bottom: -30vh;
  }

  @media screen and (width: 658px), (height: 320px) {
    height: 200vh;
    margin-bottom: -50vh;
  }

  @media screen and (width: 667px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 640px) {
    height: 200vh;
    margin-bottom: -60vh;
  }

  @media screen and (width: 568px) {
    height: 215vh;
    margin-bottom: -40vh;
  }

  @media screen and (width: 540px) {
    height: 100vh;
    margin-bottom: auto;
  }
`;

export const DaratBg = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hiden;
`;

export const ImageBg = styled.img`
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  background: #232a34;
`;

export const DaratContent = styled.div`
  z-index: 3;
  max-width: 3840px;
  position: relative;
  margin-top: -10px;
  padding: 8px 8px;
  display: flex;
  flex-direction: column;
  align-items: left;
  font-family: "Kanit", sans-serif;
`;

export const DaratRow = styled.div`
  display: grid;
  grid-auto-columns: minmax(auto, 1fr);
  align-items: top;
  margin-top: 5px;
  grid-template-areas: ${({ imgStart }) =>
    imgStart ? `'col2 col1'` : `'col1 col2'`};

  @media screen and (max-width: 768px) {
    grid-template-areas: ${({ imgStart }) =>
      imgStart ? `'col1' 'col2'` : `'col1 col1' 'col2 col2'`};
  }
`;

export const DaratH1 = styled.h1`
  color: #fff;
  font-size: 88px;
  text-align: left;
  position: relative;

  padding: 6px;
  grid-area: col1;

  @media screen and (max-width: 1100px) {
    font-size: 62px;
  }

  @media screen and (max-width: 960px) {
    font-size: 49px;
  }

  @media screen and (max-width: 860px) {
    font-size: 49px;
  }

  @media screen and (max-width: 800px) {
    font-size: 40px;
  }

  @media screen and (max-width: 780px) {
    font-size: 40px;
  }

  @media screen and (max-width: 414px) {
    font-size: 40px;
    line-height: 38px;
  }

  @media screen and (max-width: 375px) {
    font-size: 40px;
    line-height: 38px;
  }

  @media screen and (max-width: 360px) {
    font-size: 40px;
    line-height: 38px;
  }
`;

export const DaratBox = styled.form`
  background: #fff;
  height: auto;
  width: 100%;
  z-index: -1;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-direction: column;
  flex-direction: column;
  margin: 0 auto;
  margin-bottom: 20vh;
  padding: 30px;
  word-wrap: break-word;
  background: linear-gradient(
      to bottom right,
      rgba(0, 0, 0, 0.2) 0%,
      rgba(0, 0, 0, 0) 0%
    ),
    linear-gradient(to bottom right, rgba(5, 46, 105) 0%, transparent 80%);
  background-clip: border-box;
  border-radius: 0.25rem;
  position: relative;

  // @media screen and (max-width: 3840px) {
  //     margin-top: 60px;
  //     width: 1450px;
  // }

  @media screen and (max-width: 960px) {
    // margin-top: 60px;
  }

  @media screen and (max-width: 900px) {
    width: auto;
  }

  @media screen and (max-width: 780px) {
    width: auto;
  }

  @media screen and (max-width: 760px) {
    width: 560px;
  }

  @media screen and (max-width: 720px) {
    width: 660px;
  }

  @media screen and (max-width: 712px) {
    width: 500px;
  }

  @media screen and (max-width: 490px) {
    width: 450px;
  }

  @media screen and (max-width: 480px) {
    width: 320px;
  }

  @media screen and (max-width: 375px) {
    width: 290px;
  }

  @media screen and (max-width: 360px) {
    width: 290px;
  }
`;

export const DaratP = styled.p`
  margin-top: 20px;
  color: #fff;
  font-size: 24px;
  text-align: left;
  max-width: 600px;
  line-height: 32px;

  @media screen and (max-width: 780px) {
    margin-top: 0px;
    font-size: 24px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }
`;

export const DaratList = styled.p`
  margin-top: 20px;
  color: #fff;
  font-size: 18px;
  text-align: left;
  max-width: 600px;
  line-height: 32px;
  font-family: "Kanit", sans-serif;

  // @media screen and (max-width: 3840px) {
  //     font-size: 68px;
  //     line-height: 106px;
  //     max-width: 3840px;
  // }

  @media screen and (max-width: 780px) {
    font-size: 18px;
    line-height: 26px;
  }

  @media screen and (max-width: 480px) {
    font-size: 18px;
  }

  @media screen and (max-width: 414px) {
    font-size: 12px;
    line-height: 18px;
  }

  @media screen and (max-width: 375px) {
    font-size: 12px;
    line-height: 18px;
  }

  @media screen and (max-width: 360px) {
    font-size: 12px;
    line-height: 18px;
  }
`;

// ---------------------------------- ARMADA PAGE ----------------------------------

export const ArmadaContainer = styled.div`
  color: #d11329;
  background: #fff;
  margin-top: 20px;

  @media screen and (max-width: 768px) {
    margin-top: 22px;
  }
`;

export const ArmadaWrapper = styled.div`
  display: flex;
  z-index: 1;
  height: auto;
  width: 100%;
  max-width: 1100px;
  margin-right: auto;
  margin-left: auto;
  padding: 0 24px;
  justify-content: center;

  max-width: 1200px;
  display: flex;
  flex-direction: column;
  align-items: center;

  // @media screen and (max-width: 3840px) {
  //     margin-top: 1500px;
  // }

  // @media screen and (max-width: 2560px) {
  //     margin-top: 800px;
  // }

  // @media screen and (max-width: 2048px) {
  //     margin-top: 400px;
  // }

  // @media screen and (max-width: 1920px) {
  //     margin-top: 400px;
  // }

  // @media screen and (max-width: 1368px) {
  //     margin-top: 220px;
  // }

  // @media screen and (max-width: 1366px) {
  //     margin-top: 60px;
  // }

  // @media screen and (max-width: 1280px) {
  //     margin-top: 60px;
  // }

  // @media screen and (max-width: 1180px) {
  //     margin-top: 100px;
  // }

  // @media screen and (max-width: 1138px) {
  //     margin-top: 0px;
  // }

  // @media screen and (max-width: 1024px) {
  //     margin-top: 60px;
  // }

  // @media screen and (max-width: 912px) {
  //     margin-top: 640px;
  // }

  // @media screen and (max-width: 820px) {
  //     margin-top: 440px;
  // }

  // @media screen and (max-width: 780px) {
  //     margin-top: -140px;
  // }

  // @media screen and (max-width: 768px) {
  //     margin-top: 260px;
  // }

  // @media screen and (max-width: 720px) {
  //     margin-top: -220px;
  // }

  // @media screen and (max-width: 712px) {
  //     margin-top: 320px;
  // }

  // @media screen and (max-width: 540px) {
  //     margin-top: 0px;
  // }

  // @media screen and (max-width: 414px) {
  //     margin-top: 60px;
  // }

  // @media screen and (max-width: 412px) {
  //     margin-top: 80px;
  // }

  // @media screen and (max-width: 375px) {
  //     margin-top: 0px;
  // }

  // @media screen and (max-width: 360px) {
  //     margin-top: -100px;
  // }

  // @media screen and (max-width: 320px) {
  //     margin-top: -180px;
  // }
`;

export const Column2 = styled.div`
  margin-botom: 0px;
  padding: 55px;
  grid-area: col2;
`;

export const Img = styled.img`
  max-width: 100%;
  pading-right: 0;
  height: auto;

  margin-left: auto;
  margin-right: auto;
`;

export const ArmadaHead = styled.h1`
  // margin-top: 0px;
  position: relative;
  font-size: 48px;
  text-align: center;
  font-weight: 600;
  color: #052e69;
  font-family: "Roboto", sans-serif;

  @media screen and (max-width: 3840px) {
    font-size: 88px;
  }

  @media screen and (max-width: 2560px) {
    font-size: 88px;
  }

  @media screen and (max-width: 2048px) {
    font-size: 58px;
  }

  @media screen and (max-width: 1920px) {
    font-size: 48px;
  }

  @media screen and (max-width: 1366px) {
    font-size: 48px;
  }

  @media screen and (max-width: 1280px) {
    font-size: 48px;
  }

  @media screen and (max-width: 1024px) {
    font-size: 38px;
  }

  @media screen and (max-width: 720px) {
    font-size: 48px;
  }

  @media screen and (max-width: 480px) {
    font-size: 32px;
  }

  @media screen and (max-width: 360px) {
    font-size: 24px;
  }
`;

export const Red = styled.span`
  color: #cb0c0d;
`;

export const homeObjTwo = {
  id: "visiMisi",
  lightBg: true,
  lightText: false,
  lightTextDesc: false,
  headLine1: "PARTNER KAMI",
  headLine3: "HUBUNGI KAMI",
  description1: "● Sebagai solusi bagi transporter dalam efektifitas cash flow",
  description2:
    "● Semua kerusakan dalam proses pengiriman akan ditanggung oleh pihak asuransi yang bekerja sama dengan Sayap Buana Logistik",
  description3: "● Lead time dan transparansi pengiriman dijamin 100%",
  // description4: '● Pemenuhan kebutuhan pengiriman diatas 95%',
  imgStart: true,
  alt: "visiMisi",
  dark: false,
  primary: false,
  darkText: true,
};

export const AboutCard = styled.div`
  background: #fff;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  border-radius: 8px;
  max-height: 340px;
  padding: 30px;
  box-shadow: 9px 16px 16px rgba(0, 0, 0, 0.3);
  transition: all 0.2s ease-in-out;
  margin-top: -50px;
`;
