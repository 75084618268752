import React from 'react';
import Image from '../../images/cargoa.jpg';
import {
  CargoContainer,
  CargoBg,
  ImageBg,
  CargoContent,
  CargoH1,
  Yellow,
  CargoP,
} from './CargoElements';

import CargoTrans from './Cargo.translation.json';
import { LangContext } from '../../Context/lang.context';

const Cargo = () => {
  const { language } = React.useContext(LangContext);
  return (
    <>
      <CargoContainer>
        <CargoBg>
          <ImageBg src={Image} type="images/jpg" />
        </CargoBg>
        <CargoContent>
          <CargoH1>
            {CargoTrans[language].coverHeadOne}{' '}
            <Yellow>{CargoTrans[language].coverHeadTwo}</Yellow>
          </CargoH1>
          <CargoP>{CargoTrans[language].pOne}</CargoP>
          <CargoP>
            <Yellow>{CargoTrans[language].pTwo}</Yellow>
          </CargoP>
        </CargoContent>
      </CargoContainer>
    </>
  );
};

export default Cargo;
